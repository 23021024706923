import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Formik, FieldArray, ErrorMessage, FormikErrors, FormikTouched, FormikProps } from "formik";
import * as Yup from "yup";
import { Input, Button, Form as AntForm, Select, Switch } from "antd";
import * as S from "./styles";
import { IMaskInput } from "react-imask";
import api from "services/api";
import { useToast } from "hooks/toast";
import withLoader from "../../inputText";

const { Option } = Select;
type FormValues = Yup.InferType<typeof getValidationSchema>;

const IMaskInputWithLoader = withLoader(IMaskInput);
const AntInputWithLoader = withLoader(Input);

const getValidationSchema = (hasVehicles: boolean) =>
    Yup.object({
        cnpj: Yup.string().required("CNPJ é obrigatório"),
        companyName: Yup.string().required("Razão Social é obrigatório"),
        contactName: Yup.string().required("Nome do contato é obrigatório"),
        cpf: Yup.string().required("CPF é obrigatório"),
        mail: Yup.string().email("Email inválido").required("Email é obrigatório"),
        phone: Yup.string().required("Telefone é obrigatório"),
        financialContactName: Yup.string().required("Nome do contato financeiro é obrigatório"),
        financialContactEmail: Yup.string().email("Email inválido").required("Email financeiro é obrigatório"),
        address: Yup.object({
            zipCode: Yup.string().required("CEP é obrigatório"),
            street: Yup.string().required("Rua é obrigatória"),
            number: Yup.string().required("Número é obrigatório"),
            district: Yup.string().required("Bairro é obrigatório"),
            complement: Yup.string(),
            state: Yup.string().required("Estado é obrigatório"),
            city: Yup.string().required("Cidade é obrigatória"),
        }),
        UserId: Yup.string(),
        typeVehicule: hasVehicles ? Yup.string().nullable().required("Tipo de veículo é obrigatório") : Yup.string().nullable(),
        quantityVehicules: hasVehicles ? Yup.string().nullable().required("Quantidade de veículos é obrigatória") : Yup.string().nullable(),
        carRental: Yup.string()
            .nullable()
            .when("typeVehicule", {
                is: "2",
                then: Yup.string().required("Nome da locadora é obrigatório"),
                otherwise: Yup.string().nullable(),
            }),
        contacts: Yup.array().of(
            Yup.object({
                contactId: Yup.string(),
                companyId: Yup.string(),
                name: Yup.string().required("Nome do contato é obrigatório"),
                email: Yup.string().email("Email inválido").required("Email do contato é obrigatório"),
                cpf: Yup.string().required("CPF do contato é obrigatório"),
                phone: Yup.string(),
            })
        ),
        companyId: Yup.string(),
    });

const CompanyForm = forwardRef(({ onSubmitSuccess, currentTab }: any, ref) => {
    const formikRef: any = React.useRef<FormikProps<FormValues>>(null);
    const [companyInfos, setCompanyInfos] = useState<any>(null);
    const [statesBr, setStatesBr] = useState<any>([]);
    const [currentCities, setCurrentCities] = useState<any>([]);
    const [hasVehicles, setHasVehicles] = useState<boolean>(false);
    const [isNewCustomer, setIsNewCustomer] = useState<boolean>(false);
    const [isAddressLocked, setIsAddressLocked] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingAddress, setIsLoadingAddress] = useState<boolean>(false);

    const { addToast } = useToast();

    useEffect(() => {
        setCompanyInfos(null);
        const storedInfos = JSON.parse(localStorage.getItem("@FleetSolutions:companyInfos")!);
        setCompanyInfos(storedInfos);
        const fetchData = async () => {
            if (storedInfos) {
                setIsLoading(true);
                try {
                    const [statesResponse, companyResponse] = await Promise.all([api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`), api.get(`/serasa/${storedInfos?.cnpj}`)]);

                    const statesData = statesResponse.data;
                    setStatesBr(statesData);

                    const data = companyResponse.data;
                
                    if (formikRef.current && data) {
                        let stateValue = data.address.state;

                        if (typeof data.address.state === "number") {
                            const stateNameResponse = await api.get(`location/state`);
                            const stateName = stateNameResponse.data.find((state: { value: any }) => state.value === data.address.state)?.text;

                            const stateSigla = statesData.find((state: { nome: any }) => state.nome === stateName)?.sigla;

                            stateValue = stateSigla || data.address.state;
                        }

                        setHasVehicles(data.carRental || (data.typeVehicule && data.typeVehicule > 0) || data.quantityVehicules ? true : false);

                        api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateValue}/municipios`).then((response) => {
                            setCurrentCities(response.data);
                        });

                        formikRef.current.setValues({
                            cnpj: data.cnpj,
                            companyName: data.companyName || "",
                            contactName: data.contactName || "",
                            cpf: data.cpf || "",
                            mail: data.mail || "",
                            phone: data.phone || "",
                            financialContactName: data.financialContactName || "",
                            financialContactEmail: data.financialContactEmail || "",
                            address: {
                                zipCode: data.address?.zipCode || "",
                                street: data.address?.street || "",
                                number: data.address?.number || "",
                                district: data.address?.district || "",
                                complement: data.address?.complement || "",
                                state: stateValue || "",
                                city: data.address?.city || "",
                            },
                            UserId: storedInfos.userId,
                            typeVehicule: data.typeVehicule,
                            quantityVehicules: data.quantityVehicules,
                            carRental: data.carRental || "",
                            contacts: data.contacts
                                ? data.contacts.map((contact: { companyId: { toString: () => any } }) => ({
                                      ...contact,
                                      companyId: contact.companyId ? contact.companyId.toString() : "",
                                  }))
                                : [
                                      {
                                          name: "",
                                          email: "",
                                          cpf: "",
                                      },
                                  ],
                            companyId: data.companyId.toString(),
                        }, false);
                        setIsNewCustomer(false);
                    } else {
                        setIsNewCustomer(true);
                        formikRef.current.setValues(
                            {
                                cnpj: storedInfos.cnpj,
                                UserId: storedInfos.userId?.toString(),
                                companyName: "",
                                contactName: "",
                                cpf: "",
                                mail: "",
                                phone: "",
                                financialContactName: "",
                                financialContactEmail: "",
                                address: {
                                    zipCode: "",
                                    street: "",
                                    number: "",
                                    district: "",
                                    complement: "",
                                    state: "",
                                    city: "",
                                },
                                typeVehicule: null,
                                quantityVehicules: 0,
                                carRental: "",
                                contacts: [
                                    {
                                        name: "",
                                        email: "",
                                        cpf: "",
                                    },
                                ],
                                companyId: 0,
                            },
                            false // Este segundo parâmetro evita que o Formik dispare a validação automática.
                        );
                    }
                } catch (error) {
                    console.error("Erro ao buscar dados:", error);
                } finally {
                    setIsLoading(false);
                }
            }
        };
        if (currentTab === "2") {
            fetchData();
        }
    }, [currentTab]);

    useImperativeHandle(ref, () => ({
        submitForm: () => formikRef.current?.submitForm(),
    }));

    const handleCities = (value: any) => {
        api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`).then((response) => {
            setCurrentCities(response.data);
        });
    };

    const handleKeyUpCep = (e: React.KeyboardEvent<HTMLInputElement>, setFieldValue: any, resetForm: any) => {
        let value = (e.target as HTMLInputElement).value;
        value = value.replace(/\D/g, "");

        if (value.length === 8) {
            setIsLoadingAddress(true)
            api.get(`/ceps?cep=${value}`)
                .then((response: any) => {
                    if (!response.data.erro) {
                        const endereco = response.data;
                        setFieldValue("address.street", endereco.logradouro);
                        setFieldValue("address.district", endereco.bairro);
                        setFieldValue("address.state", endereco.uf);
                        setFieldValue("address.city", endereco.localidade);
                        setIsAddressLocked(true);
                    } else {
                        resetForm({
                            values: {
                                ...formikRef.current.values,
                                address: {
                                    zipCode: value,
                                    street: "",
                                    number: "",
                                    district: "",
                                    complement: "",
                                    state: "",
                                    city: "",
                                },
                            },
                        });
                        setIsAddressLocked(false);
                        addToast({
                            title: "Endereço não encontrado",
                            type: "error",
                            description: "Preencha os campos de estado e cidade manualmente.",
                        });
                    }
                    setIsLoadingAddress(false)

                })
                .catch((error: any) => {
                    setIsLoadingAddress(false)

                    resetForm({
                        values: {
                            ...formikRef.current.values,
                            address: {
                                zipCode: value,
                                street: "",
                                number: "",
                                district: "",
                                complement: "",
                                state: "",
                                city: "",
                            },
                        },
                    });
                    setIsAddressLocked(false);
                    addToast({
                        title: "Erro",
                        type: "error",
                        description: "Endereço não encontrado.",
                    });
                });
        }
    };

    return (
        <Formik
            initialValues={{
                cnpj: companyInfos?.cnpj,
                companyName: "",
                contactName: "",
                cpf: "",
                mail: "",
                phone: "",
                financialContactName: "",
                financialContactEmail: "",
                address: {
                    zipCode: "",
                    street: "",
                    number: "",
                    district: "",
                    complement: "",
                    state: "",
                    city: "",
                },
                UserId: "",
                typeVehicule: 0,
                quantityVehicules: "",
                carRental: "",
                contacts: [
                    {
                        contactId: "",
                        companyId: "",
                        name: "",
                        email: "",
                        cpf: "",
                        phone: "",
                    },
                ],
                companyId: "",
            }}
            validationSchema={() => getValidationSchema(hasVehicles)}
            validateOnBlur
            validateOnChange
            onSubmit={async (values) => {
                const cleanedValues = {
                    ...values,
                    cpf: values.cpf.replace(/\D/g, ""), // Remove qualquer máscara do CPF
                    phone: values.phone.replace(/\D/g, ""), // Remove qualquer máscara do telefone
                    contacts: values.contacts.map((contact) => {
                        const cleanedContact = Object.fromEntries(
                            Object.entries(contact).map(([key, value]) => {
                                if ((key === 'cpf' || key === 'phone') && typeof value === 'string') {
                                    return [key, value.replace(/\D/g, "")]; // Limpa a máscara
                                }
                                return [key, value];
                            })
                        );
                        return cleanedContact;
                    }),
                };
            
                const request = isNewCustomer ? api.post(`/company`, cleanedValues) : api.put(`/company`, cleanedValues);
                try {
                    const res = await request;
                    const data = res.data;
                    if (data) {
                        onSubmitSuccess();
                    }
                } catch (error) {
                    console.error("Erro ao buscar dados:", error);
                }
            }}
            innerRef={formikRef as any}
        >
            {({ values, handleChange, handleBlur, touched, errors, handleSubmit, validateForm, setTouched, setFieldValue }) => (
                <S.CompanyForm>
                    <AntForm layout="vertical">
                        <div className="fields-group">
                            <div className="title">Informações Gerais</div>
                            <div className="fields">
                                <div className="fields-row">
                                    <AntForm.Item
                                        label={
                                            <span>
                                                CNPJ<span style={{ color: "red" }}> *</span>
                                            </span>
                                        }
                                        validateStatus={errors.cnpj && touched.cnpj ? "error" : ""}
                                        help={touched.cnpj && errors.cnpj}
                                    >
                                        <IMaskInputWithLoader
                                            isLoading={isLoading}
                                            mask="00.000.000/0000-00"
                                            unmask={true}
                                            name="cnpj"
                                            value={values.cnpj}
                                            onAccept={(value: any) => setFieldValue("cnpj", value.replace(/\D/g, ""))}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder="Digite o CNPJ"
                                            className="ant-input"
                                            disabled
                                        />
                                    </AntForm.Item>

                                    <AntForm.Item
                                        label={
                                            <span>
                                                Razão Social<span style={{ color: "red" }}> *</span>
                                            </span>
                                        }
                                        validateStatus={errors.companyName && touched.companyName ? "error" : ""}
                                        help={touched.companyName && errors.companyName}
                                    >
                                        <AntInputWithLoader isLoading={isLoading} disabled={isLoading} name="companyName" value={values.companyName} placeholder="Digite a razão social" onChange={handleChange} onBlur={handleBlur} />
                                    </AntForm.Item>
                                </div>
                            </div>
                        </div>

                        <div className="fields-group">
                            <div className="title">Contato da Empresa</div>
                            <div className="fields">
                                <div className="fields-row">
                                    <AntForm.Item
                                        label={
                                            <span>
                                                Nome Completo<span style={{ color: "red" }}> *</span>
                                            </span>
                                        }
                                        validateStatus={errors.contactName && touched.contactName ? "error" : ""}
                                        help={touched.contactName && errors.contactName}
                                    >
                                        <AntInputWithLoader isLoading={isLoading} disabled={isLoading} name="contactName" value={values.contactName} placeholder="Digite o nome completo" onChange={handleChange} onBlur={handleBlur} />
                                    </AntForm.Item>

                                    <AntForm.Item
                                        label={
                                            <span>
                                                CPF<span style={{ color: "red" }}> *</span>
                                            </span>
                                        }
                                        validateStatus={errors.cpf && touched.cpf ? "error" : ""}
                                        help={touched.cpf && errors.cpf}
                                    >
                                        <IMaskInputWithLoader
                                            isLoading={isLoading}
                                            disabled={isLoading}
                                            mask="000.000.000-00"
                                            unmask={true}
                                            name="cpf"
                                            value={values.cpf}
                                            onAccept={(value: any, maskInstance: any) => {
                                                console.log("Valor não mascarado:", maskInstance.unmaskedValue);
                                                setFieldValue("cpf", maskInstance.unmaskedValue);
                                            }}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder="Digite o CPF"
                                            className="ant-input"
                                        />
                                    </AntForm.Item>
                                    <AntForm.Item
                                        label={
                                            <span>
                                                Celular<span style={{ color: "red" }}> *</span>
                                            </span>
                                        }
                                        validateStatus={errors.phone && touched.phone ? "error" : ""}
                                        help={touched.phone && errors.phone}
                                    >
                                        <IMaskInputWithLoader
                                            isLoading={isLoading}
                                            disabled={isLoading}
                                            mask="(00) 00000-0000"
                                            unmask={true}
                                            name="phone"
                                            value={values.phone}
                                            onAccept={(value: any) => setFieldValue("phone", value.replace(/\D/g, ""))}
                                            onBlur={handleBlur}
                                            placeholder="Insira o Celular"
                                            className="ant-input"
                                        />
                                    </AntForm.Item>
                                    <AntForm.Item
                                        label={
                                            <span>
                                                E-mail<span style={{ color: "red" }}> *</span>
                                            </span>
                                        }
                                        validateStatus={errors.mail && touched.mail ? "error" : ""}
                                        help={touched.mail && errors.mail}
                                    >
                                        <AntInputWithLoader isLoading={isLoading} disabled={isLoading} name="mail" value={values.mail} placeholder="Digite o e-mail" type="email" onChange={handleChange} onBlur={handleBlur} />
                                    </AntForm.Item>
                                </div>
                            </div>
                        </div>

                        <div className="fields-group">
                            <div className="title">Contato Financeiro</div>
                            <div className="fields">
                                <div className="fields-row">
                                    <AntForm.Item
                                        label={
                                            <span>
                                                Nome Completo<span style={{ color: "red" }}> *</span>
                                            </span>
                                        }
                                        validateStatus={errors.financialContactName && touched.financialContactName ? "error" : ""}
                                        help={touched.financialContactName && errors.financialContactName}
                                    >
                                        <AntInputWithLoader isLoading={isLoading} disabled={isLoading} name="financialContactName" value={values.financialContactName} placeholder="Digite o nome completo" onChange={handleChange} onBlur={handleBlur} />
                                    </AntForm.Item>

                                    <AntForm.Item
                                        label={
                                            <span>
                                                E-mail<span style={{ color: "red" }}> *</span>
                                            </span>
                                        }
                                        validateStatus={errors.financialContactEmail && touched.financialContactEmail ? "error" : ""}
                                        help={touched.financialContactEmail && errors.financialContactEmail}
                                    >
                                        <AntInputWithLoader isLoading={isLoading} disabled={isLoading} name="financialContactEmail" value={values.financialContactEmail} placeholder="Digite o e-mail" type="email" onChange={handleChange} onBlur={handleBlur} />
                                    </AntForm.Item>
                                </div>
                            </div>
                        </div>

                        <div className="fields-group">
                            <div className="title">Signatários</div>
                            <div className="fields">
                                <FieldArray name="contacts">
                                    {({ push, remove }) => (
                                        <>
                                            {values?.contacts?.map((contact, index) => (
                                                <div className="fields-row" key={index}>
                                                    <AntForm.Item
                                                        label={
                                                            <span>
                                                                Nome Completo<span style={{ color: "red" }}> *</span>
                                                            </span>
                                                        }
                                                        validateStatus={errors.contacts && errors.contacts[index] && (errors.contacts[index] as FormikErrors<(typeof values.contacts)[0]>)?.name && touched.contacts && touched.contacts[index] && touched.contacts[index].name ? "error" : ""}
                                                        help={touched.contacts && touched.contacts[index] && touched.contacts[index].name && errors.contacts && (errors.contacts[index] as FormikErrors<(typeof values.contacts)[0]>)?.name}
                                                    >
                                                        <AntInputWithLoader
                                                            isLoading={isLoading}
                                                            disabled={isLoading}
                                                            name={`contacts.${index}.name`}
                                                            placeholder="Digite o nome completo"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={contact.name} // Adiciona o valor
                                                        />
                                                    </AntForm.Item>

                                                    <AntForm.Item
                                                        label={
                                                            <span>
                                                                CPF<span style={{ color: "red" }}> *</span>
                                                            </span>
                                                        }
                                                        validateStatus={errors.contacts && errors.contacts[index] && (errors.contacts[index] as FormikErrors<(typeof values.contacts)[0]>)?.cpf && touched.contacts && touched.contacts[index] && touched.contacts[index].cpf ? "error" : ""}
                                                        help={touched.contacts && touched.contacts[index] && touched.contacts[index].cpf && errors.contacts && (errors.contacts[index] as FormikErrors<(typeof values.contacts)[0]>)?.cpf}
                                                    >
                                                        <IMaskInputWithLoader
                                                            isLoading={isLoading}
                                                            disabled={isLoading}
                                                            mask="000.000.000-00"
                                                            unmask={true}
                                                            name={`contacts.${index}.cpf`}
                                                            value={contact.cpf}
                                                            onAccept={(value: any) => setFieldValue(`contacts.${index}.cpf`, value.replace(/\D/g, ""))}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            placeholder="Digite o CPF"
                                                            className="ant-input"
                                                        />
                                                    </AntForm.Item>

                                                    <AntForm.Item
                                                        label={
                                                            <span>
                                                                E-mail<span style={{ color: "red" }}> *</span>
                                                            </span>
                                                        }
                                                        validateStatus={errors.contacts && errors.contacts[index] && (errors.contacts[index] as FormikErrors<(typeof values.contacts)[0]>)?.email && touched.contacts && touched.contacts[index] && touched.contacts[index].email ? "error" : ""}
                                                        help={touched.contacts && touched.contacts[index] && touched.contacts[index].email && errors.contacts && (errors.contacts[index] as FormikErrors<(typeof values.contacts)[0]>)?.email}
                                                    >
                                                        <AntInputWithLoader
                                                            isLoading={isLoading}
                                                            disabled={isLoading}
                                                            name={`contacts.${index}.email`}
                                                            placeholder="Digite o e-mail"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={contact.email} // Adiciona o valor
                                                        />
                                                    </AntForm.Item>

                                                    <Button type="primary" className="remove-contact" onClick={() => remove(index)}>
                                                        Remover
                                                    </Button>
                                                </div>
                                            ))}
                                            <Button type="primary" className="add-contact" onClick={() => push({  name: "", email: "", cpf: "" })}>
                                                Adicionar Novo Signatário
                                            </Button>
                                        </>
                                    )}
                                </FieldArray>
                            </div>
                        </div>

                        <div className="fields-group">
                            <div className="title">Endereço</div>
                            <div className="fields">
                                <div className="fields-row">
                                    <AntForm.Item
                                        label={
                                            <span>
                                                CEP<span style={{ color: "red" }}> *</span>
                                            </span>
                                        }
                                        validateStatus={errors.address?.zipCode && touched.address?.zipCode ? "error" : ""}
                                        help={touched.address?.zipCode && errors.address?.zipCode}
                                    >
                                        <IMaskInputWithLoader
                                            isLoading={isLoading || isLoadingAddress}
                                            disabled={isLoading || isLoadingAddress}
                                            mask="00000-000"
                                            name="address.zipCode"
                                            value={values?.address?.zipCode}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                const value = event.target.value.replace(/-/g, ""); // Remove o hífen ao alterar
                                                setFieldValue("address.zipCode", value);
                                            }}
                                            onBlur={handleBlur}
                                            onKeyUp={(e: any) => handleKeyUpCep(e, setFieldValue, formikRef.current.resetForm)} // Passa setFieldValue e resetForm
                                            placeholder="Insira o CEP"
                                            className="ant-input"
                                        />
                                    </AntForm.Item>

                                    <AntForm.Item
                                        label={
                                            <span>
                                                Endereço<span style={{ color: "red" }}> *</span>
                                            </span>
                                        }
                                        validateStatus={errors.address?.street && touched.address?.street ? "error" : ""}
                                        help={touched.address?.street && errors.address?.street}
                                    >
                                        <AntInputWithLoader isLoading={isLoading || isLoadingAddress} disabled={isLoading || isLoadingAddress} name="address.street" value={values?.address?.street} placeholder="Digite o endereço" onChange={handleChange} onBlur={handleBlur} />
                                    </AntForm.Item>

                                    <AntForm.Item
                                        label={
                                            <span>
                                                Número<span style={{ color: "red" }}> *</span>
                                            </span>
                                        }
                                        validateStatus={errors.address?.number && touched.address?.number ? "error" : ""}
                                        help={touched.address?.number && errors.address?.number}
                                    >
                                        <AntInputWithLoader isLoading={isLoading || isLoadingAddress} disabled={isLoading || isLoadingAddress} name="address.number" value={values?.address?.number} placeholder="Insira o número" onChange={handleChange} onBlur={handleBlur} />
                                    </AntForm.Item>
                                </div>
                                <div className="fields-row">
                                    <AntForm.Item
                                        label={
                                            <span>
                                                Complemento<span style={{ color: "#9E9E9E" }}> (Opcional)</span>
                                            </span>
                                        }
                                    >
                                        <AntInputWithLoader isLoading={isLoading || isLoadingAddress} disabled={isLoading || isLoadingAddress} name="address.complement" value={values?.address?.complement} placeholder="Digite o complemento" onChange={handleChange} onBlur={handleBlur} />
                                    </AntForm.Item>

                                    <AntForm.Item
                                        label={
                                            <span>
                                                Bairro<span style={{ color: "red" }}> *</span>
                                            </span>
                                        }
                                        validateStatus={errors.address?.district && touched.address?.district ? "error" : ""}
                                        help={touched.address?.district && errors.address?.district}
                                    >
                                        <AntInputWithLoader isLoading={isLoading || isLoadingAddress} disabled={isLoading || isLoadingAddress} name="address.district" value={values?.address?.district} placeholder="Digite o nome do bairro" onChange={handleChange} onBlur={handleBlur} />
                                    </AntForm.Item>

                                    <AntForm.Item
                                        label={
                                            <span>
                                                Estado<span style={{ color: "red" }}> *</span>
                                            </span>
                                        }
                                        validateStatus={errors.address?.state && touched.address?.state ? "error" : ""}
                                        help={touched.address?.state && errors.address?.state}
                                    >
                                        <Select
                                            onChange={(value) => {
                                                setFieldValue("address.state", value);
                                                setFieldValue("address.city", ""); // Limpa a cidade ao mudar o estado
                                                handleCities(value);
                                            }}
                                            onBlur={handleBlur}
                                            value={values?.address?.state || "Selecione o Estado"}
                                            disabled={isAddressLocked || isLoading || isLoadingAddress} // Bloqueia o campo se o endereço for encontrado
                                            loading={isLoading || isLoadingAddress}
                                        >
                                            {statesBr &&
                                                statesBr.map((subItem: any, idx: number) => (
                                                    <Option key={idx} value={subItem.sigla}>
                                                        {subItem.sigla}
                                                    </Option>
                                                ))}
                                        </Select>
                                    </AntForm.Item>

                                    <AntForm.Item
                                        label={
                                            <span>
                                                Cidade<span style={{ color: "red" }}> *</span>
                                            </span>
                                        }
                                        validateStatus={errors.address?.city && touched.address?.city ? "error" : ""}
                                        help={touched.address?.city && errors.address?.city}
                                    >
                                        <Select
                                            onChange={(value) => setFieldValue("address.city", value)}
                                            onBlur={handleBlur}
                                            value={values?.address?.city || "Selecione a Cidade"}
                                            disabled={isAddressLocked || !values?.address?.state || isLoading || isLoadingAddress} // Bloqueia se o estado estiver bloqueado ou vazio
                                            loading={isLoading || isLoadingAddress}
                                        >
                                            {currentCities &&
                                                currentCities.map((subItem: any, idx: number) => (
                                                    <Option key={idx} value={subItem.nome}>
                                                        {subItem.nome}
                                                    </Option>
                                                ))}
                                        </Select>
                                    </AntForm.Item>
                                </div>
                            </div>
                        </div>

                        <div className="fields-group mb-3">
                            <div className="title">Veículos</div>
                            <div className="fields">
                                <div className="fields-row ">
                                    <AntForm.Item className="switch-group smaller" label={<span>A empresa possui veículos?</span>}>
                                        <Switch
                                            checked={hasVehicles}
                                            onChange={(value) => {
                                                setHasVehicles(value);
                                                if (!value) {
                                                    formikRef.current.setValues({
                                                        ...formikRef.current.values,
                                                        typeVehicule: null,
                                                        quantityVehicules: 0,
                                                        carRental: "",
                                                    });
                                                } else {
                                                    formikRef.current.setValues({
                                                        ...formikRef.current.values,
                                                        quantityVehicules: 1,
                                                        typeVehicule: "1",
                                                    });
                                                }
                                            }}
                                        />
                                        <div className="switch-value">{hasVehicles ? "Sim" : "Não"}</div>
                                    </AntForm.Item>

                                    {hasVehicles && (
                                        <>
                                            <AntForm.Item
                                                label={
                                                    <span>
                                                        Quantidade de veículos<span style={{ color: "red" }}> *</span>
                                                    </span>
                                                }
                                                validateStatus={errors.quantityVehicules && touched.quantityVehicules ? "error" : ""}
                                                help={touched.quantityVehicules && errors.quantityVehicules}
                                            >
                                                <AntInputWithLoader isLoading={isLoading} disabled={isLoading} name="quantityVehicules" value={values.quantityVehicules} type="number" onChange={handleChange} onBlur={handleBlur} />
                                            </AntForm.Item>
                                            <AntForm.Item
                                                label={
                                                    <span>
                                                        Tipo de veículo<span style={{ color: "red" }}> *</span>
                                                    </span>
                                                }
                                                validateStatus={errors.typeVehicule && touched.typeVehicule ? "error" : ""}
                                                help={touched.typeVehicule && errors.typeVehicule}
                                            >
                                                <Select
                                                    onChange={(value) => {
                                                        setFieldValue("typeVehicule", value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.typeVehicule?.toString() || "Selecione o tipo"}
                                                >
                                                    <Option key={1} value={"1"}>
                                                        Próprio
                                                    </Option>
                                                    <Option key={2} value={"2"}>
                                                        Alugado
                                                    </Option>
                                                </Select>
                                            </AntForm.Item>
                                            {values.typeVehicule?.toString() === "2" && (
                                                <>
                                                    <AntForm.Item
                                                        label={
                                                            <span>
                                                                Locadora<span style={{ color: "red" }}> *</span>
                                                            </span>
                                                        }
                                                        validateStatus={errors.carRental && touched.carRental ? "error" : ""}
                                                        help={touched.carRental && errors.carRental}
                                                    >
                                                        <AntInputWithLoader isLoading={isLoading} disabled={isLoading} name="carRental" type="text" value={values.carRental} onChange={handleChange} onBlur={handleBlur} />
                                                    </AntForm.Item>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </AntForm>
                </S.CompanyForm>
            )}
        </Formik>
    );
});

export default CompanyForm;
