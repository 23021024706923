import React from "react";
import { BreadcrumbContent } from "./styles";
import BreadcrumbIcon from "../../../../../../assets/icons/breadcrumb-arrow.svg";
import { useHistory } from "react-router-dom";


const OrderResume: React.FC<any> = ({ menuItems }) => {
    const history = useHistory()
    
    const handleNavigate = (menu: any) => {
        console.log(menu);
        if(menu.url){
            history.push(menu.url)
        }
    };

    return (
        <BreadcrumbContent>
            <div className="container-xxl">
                <div className="row">
                    <div className="col p-0">
                        <div className="breadcrumb-menu">
                            {menuItems?.map((menu: any, index: any) => (
                                <React.Fragment key={index}>
                                    <div
                                        className={`item ${!menu?.url ? 'current' : ''}`}
                                        onClick={() => handleNavigate(menu)}
                                    >
                                        {menu?.label}
                                    </div>
                                    {index < menuItems.length - 1 && (
                                        <img
                                            src={BreadcrumbIcon}
                                            alt="Arrow"
                                            className="arrow-icon"
                                        />
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </BreadcrumbContent>
    );
};

export default OrderResume;
