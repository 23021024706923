import { KeyboardDatePicker } from '@material-ui/pickers'

import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0)
    }
`

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  animation: ${appearFromLeft} 1s;

  .wrapper-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    div {
      width: 190px;
    }

    button {
      width: 190px;
      height: 41px;
      font-size: 12px;
      text-transform: uppercase;
      margin: 8px;
    }

    .btn-back {
      background: transparent;
      border: 2px solid #226fbb;
      color: #226fbb;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  width: 100%;
  max-width: 700px;

  h1 {
    width: inherit;
    display: flex;
    justify-content: center;
    color: #17536c;
    font-size: 25px;
    text-align: center;
  }

  p {
    font-size: 20px;
    color: #3c505a;
    text-align: center;
    margin-bottom: 5px;
  }
    span.required-infos{
        font-size: 10px;
        margin-bottom: 20px;
    }

  @media (max-width: 1024px) {
    max-width: initial;
  }
`

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 850px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  h2 {
    width: inherit;
    display: flex;
    justify-content: center;
    margin: 10px 0 30px 0;
    color: #236fbc;
    font-size: 16px;
    text-align: center;
  }

  @media (max-width: 984px) {
    form {
      flex-direction: column;
      justify-content: center;
    }
  }

  @media (max-width: 400px) {
    form {
      flex-direction: row;
    }
  }
`

export const InputColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 16px;

  & > div {
    width: 400px;
  }

  .custom-selects {
    .ant-select {
      .ant-select-selector {
        border-radius: 23px !important;
        height: 45px !important;
        width: 400px !important ;
        border: 1px solid #cfd7d9 !important;
      }

      .anticon {
        margin-left: -10px;
      }
    }
  }

  .address-selects{
    .ant-select-selection-item{
      color:#242424
    }
  }

  .custom-select {
    .ant-select-selection-item {
      margin-top: -40px;
    }

    .ant-select-arrow {
      margin-top: -14px;
    }
  }

  .anticon svg{
      margin-right: 0 !important;
    }

    .MuiFormControl-fullWidth{
      margin: 0;
    }

    .birth-field{
    width: 100%;
    display: flex;
    flex: 1;
    position: relative;
    .MuiFormControl-root{
      width: 100%;
    }
  }
`

export const DatePicker = styled(KeyboardDatePicker)`
  height: 45px !important;



  .MuiInputBase-root {
    border-radius: 23px;
    border: 2px solid #cfd7d9;
    background-color: white;
    color: #797979;
    height: 50px !important;
    width:100%;

  }

  .MuiOutlinedInput-root {
    border: none;
  }

  .MuiInputAdornment-root {
    margin-right: -340px;
  }

  .MuiButtonBase-root {
    width: 0;
    height: 0;
    &:hover {
      background-color: transparent !important;
    }

    &:active {
      background: none !important;
    }

    span,
    svg {
      margin-left: -45px;
    }
  }

  .MuiFormHelperText-root.Mui-error {
    color: #f44336;
    display: flex;
    margin-top: -20px;
    font-size: 12px;
  }
`

export const Wrapper = styled.div`
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0 !important;
  flex-wrap: wrap;

  & > p {
    color: #236fbc;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }

  .ant-radio-group {
    display: flex;
    margin-bottom: 0;
    align-items: center;

    .ant-radio-wrapper {
      display: flex;
      &:first-child {
        margin: 0 50px 0 43px;
      }

      .ant-radio {
        & > span {
          width: 30px;
          height: 30px;

          &::after {
            width: 15px;
            height: 15px;
            top: 6px;
            left: 7px;
          }
        }
      }

      & > span {
        color: #236fbc;
        font-size: 16px;
        font-weight: 600;
        width: unset;
      }
    }
  }

  @media (max-width: 984px) {
    width: auto !important;
  }
`

export const WrapperDoc = styled.div`
  background: #ffffff;
  border: 1px solid #005faf;
  border-radius: 36px;
  width: 100%;
  max-width: 787px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  margin: 12px;

  .left {
    p {
      margin: 0;
      color: #005faf;
      font-size: 14px;
      font-weight: bold;
    }

    .name-file {
      font-size: 18px;
      font-weight: bold;

      span {
        font-weight: 500;
      }
    }

    .know-more {
      color: #005faf;
      font-size: 12px;
      padding: 5px;
    }
  }

  .more-info {
    text-decoration: underline;
    font-size: 14px;
    color: #000;
    cursor: pointer;
  }

  .right {
    .upload {
      flex-direction: column;

      .ant-tooltip-inner {
        display: none;
      }
    }

    span.anticon.anticon-delete {
      display: block;
    }

    .btn-upload {
      width: 192px;
      border: 3px solid #005faf;
      background: #fff;
      color: #005faf;
      font-size: 11px;
      margin-left: 12px;
      align-items: center;
      display: flex;
      justify-content: space-between;

      svg {
        color: #005faf;
      }
    }
  }

  @media (max-width: 1024px) {
    height: auto;
    flex-direction: column;
    width: auto;

    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`
