import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import * as S from "./styles";
import UploadFile from "../../shared/uploadFile";
import api from "services/api";

const CompanyUploads = ({ onSubmitSuccess, currentTab }: any) => {
    const [companyInfos, setCompanyInfos] = useState<any>(JSON.parse(localStorage.getItem("@FleetSolutions:companyInfos")!) || null);
    const [downloadDocCcmei, setDownloadDocCcmei] = useState("");
    const [downloadDocCcmeiExt, setDownloadDocCcmeiExt] = useState("");
    const [companyType, setCompanyType] = useState(null);
    const [showProcuration, setShowProcuration] = useState(true);
    const [downloadDocSimplesStatementLastYear, setDownloadDocSimplesStatementLastYear] = useState("");
    const [downloadDocSimplesStatementLastYearExt, setDownloadDocSimplesStatementLastYearExt] = useState("");
    const [downloadDocLastYearSocialIncomeTax, setDownloadDocLastYearSocialIncomeTax] = useState("");
    const [downloadDocLastYearSocialIncomeTaxExt, setDownloadDocLastYearSocialIncomeTaxExt] = useState("");
    const [downloadDocLastContractChange, setDownloadDocLastContractChange] = useState("");
    const [downloadDocLastContractChangeExt, setDownloadDocLastContractChangeExt] = useState("");
    const [downloadDocIrpjLastYear, setDownloadDocIrpjLastYear] = useState("");
    const [downloadDocIrpjLastYearExt, setDownloadDocIrpjLastYearExt] = useState("");
    const [downloadDocLastYearRevenue, setDownloadDocLastYearRevenue] = useState("");
    const [downloadDocLastYearRevenueExt, setDownloadDocLastYearRevenueExt] = useState("");
    const [downloadDocBalanceSheetPreviousYear, setDownloadDocBalanceSheetPreviousYear] = useState("");
    const [downloadDocBalanceSheetPreviousYearExt, setDownloadDocBalanceSheetPreviousYearExt] = useState("");
    const [downloadDocCurrentYearBalanceSheet, setDownloadDocCurrentYearBalanceSheet] = useState("");
    const [downloadDocCurrentYearBalanceSheetExt, setDownloadDocCurrentYearBalanceSheetExt] = useState("");
    const [downloadDocDrePreviousYear, setDownloadDocDrePreviousYear] = useState("");
    const [downloadDocDrePreviousYearExt, setDownloadDocDrePreviousYearExt] = useState("");
    const [downloadDocDreCurrentYear, setDownloadDocDreCurrentYear] = useState("");
    const [downloadDocDreCurrentYearExt, setDownloadDocDreCurrentYearExt] = useState("");
    const [downloadDocBankIndebtedness, setDownloadDocBankIndebtedness] = useState("");
    const [downloadDocBankIndebtednessExt, setDownloadDocBankIndebtednessExt] = useState("");

    const [downloadDocProcuration, setDownloadDocProcuration] = useState("");
    const [downloadDocProcurationExt, setDownloadDocProcurationExt] = useState("");
    const [downloadDocOthers, setDownloadDocOthers] = useState("");
    const [downloadDocOthersExt, setDownloadDocOthersExt] = useState("");

    useEffect(() => {
        const fetchCompanyData = async () => {
            if (companyInfos) {
                setCompanyInfos(null)
                const storedInfos = JSON.parse(localStorage.getItem("@FleetSolutions:companyInfos")!)
                setCompanyInfos(storedInfos)
                try {
                    const companyResponse = await api.get(`/document-company-upload/${storedInfos?.userId}`);
                    const data = companyResponse.data;
                    setDownloadDocCcmei(data.ccmei || "");
                    setDownloadDocCcmeiExt(data.ccmeiExt || "");
                    setDownloadDocSimplesStatementLastYear(data.simplesStatementLastYear || "");
                    setDownloadDocSimplesStatementLastYearExt(data.simplesStatementLastYearExt || "");
                    setDownloadDocLastYearSocialIncomeTax(data.lastYearSocialIncomeTax || "");
                    setDownloadDocLastYearSocialIncomeTaxExt(data.lastYearSocialIncomeTaxExt || "");
                    setDownloadDocLastContractChange(data.lastContractChange || "");
                    setDownloadDocLastContractChangeExt(data.lastContractChangeExt || "");
                    setDownloadDocIrpjLastYear(data.irpjLastYear || "");
                    setDownloadDocIrpjLastYearExt(data.irpjLastYearExt || "");
                    setDownloadDocLastYearRevenue(data.lastYearRevenue || "");
                    setDownloadDocLastYearRevenueExt(data.lastYearRevenueExt || "");
                    setDownloadDocBalanceSheetPreviousYear(data.balanceSheetPreviousYear || "");
                    setDownloadDocBalanceSheetPreviousYearExt(data.balanceSheetPreviousYearExt || "");
                    setDownloadDocCurrentYearBalanceSheet(data.currentYearBalanceSheet || "");
                    setDownloadDocCurrentYearBalanceSheetExt(data.currentYearBalanceSheetExt || "");
                    setDownloadDocDrePreviousYear(data.drePreviousYear || "");
                    setDownloadDocDrePreviousYearExt(data.drePreviousYearExt || "");
                    setDownloadDocDreCurrentYear(data.dreCurrentYear || "");
                    setDownloadDocDreCurrentYearExt(data.dreCurrentYearExt || "");
                    setDownloadDocBankIndebtedness(data.bankIndebtedness || "");
                    setDownloadDocBankIndebtednessExt(data.bankIndebtednessExt || "");
                    setDownloadDocProcuration(data.procuration || "");
                    setDownloadDocProcurationExt(data.procurationExt || "");
                    setDownloadDocOthers(data.others || "");
                    setDownloadDocOthersExt(data.othersExt || "");
                    setCompanyType(data.companyType);
                } catch (error) {
                    console.error("Erro ao buscar dados da empresa:", error);
                }
            }
        };
        if(currentTab === '3'){
            fetchCompanyData();
        }
    }, [currentTab]);


    return (
        <S.UploadsContent>
            <h2>
                Documentação
            </h2>
            <span className="subtitle">
                Envie os documentos abaixo para prosseguirmos com a validação dos dados cadastrais.
            </span>
            <S.GridContainer>
                <UploadFile extensionFile={downloadDocCcmeiExt} onRemove={() => setDownloadDocCcmei('')}  numberDoc={1} downloadDoc={downloadDocCcmei} docName="Contrato Social ou CCMEI" description="(Obrigatório)" fileType="Arquivos PDF até 5 mb" typeDocName="contrato-social-ou-ccmei" />

                {companyType === 1 && (
                    <>
                        <UploadFile extensionFile={downloadDocSimplesStatementLastYearExt}  onRemove={() => setDownloadDocSimplesStatementLastYear('')}  numberDoc={2} downloadDoc={downloadDocSimplesStatementLastYear} docName="Declaração do SIMPLES Último Ano" description="(Obrigatório)" fileType="Arquivos PDF até 5 mb" typeDocName="declaracao-simples-ultimo-ano" />
                        <UploadFile extensionFile={downloadDocLastYearSocialIncomeTaxExt}  onRemove={() => setDownloadDocLastYearSocialIncomeTax('')}  numberDoc={3} downloadDoc={downloadDocLastYearSocialIncomeTax} docName="IRPF do Socio Último Ano" description="(Obrigatório)" fileType="Arquivos PDF até 5 mb" typeDocName="irpf-socio-ultimo-ano" />
                    </>
                )}

                {(companyType === 0 || companyType === null || companyType === 2 || companyType === 3 || companyType === undefined || companyType === 4) && (
                    <UploadFile extensionFile={downloadDocLastContractChangeExt}  onRemove={() => setDownloadDocLastContractChange('')}  numberDoc={4} downloadDoc={downloadDocLastContractChange} docName="Última Alteração Contratual" description="(Obrigatório)" fileType="Arquivos PDF até 5 mb" typeDocName="ultima-alteracao-contratual" />
                )}

                {(companyType === 2 || companyType === 3) && (
                    <>
                        <UploadFile extensionFile={downloadDocIrpjLastYearExt}  onRemove={() => setDownloadDocIrpjLastYear('')}  numberDoc={5} downloadDoc={downloadDocIrpjLastYear} docName="IRPJ Último Ano" description="(Obrigatório)" fileType="Arquivos PDF até 5 mb" typeDocName="irpj-ultimo-ano" />
                        <UploadFile extensionFile={downloadDocLastYearRevenueExt}  onRemove={() => setDownloadDocLastYearRevenue('')}  numberDoc={6} downloadDoc={downloadDocLastYearRevenue} docName="Relação de Faturamento Último Ano" description="(Obrigatório)" fileType="Arquivos PDF até 5 mb" typeDocName="relacao-faturamento-ultimo-ano" />
                    </>
                )}

                {(companyType === 4 || companyType === 0 || companyType === null || companyType === undefined) && (
                    <>
                        <UploadFile extensionFile={downloadDocBalanceSheetPreviousYearExt}  onRemove={() => setDownloadDocBalanceSheetPreviousYear('')}  numberDoc={7} downloadDoc={downloadDocBalanceSheetPreviousYear} docName="Balanço ano anterior" description="(Obrigatório)" fileType="Arquivos PDF até 5 mb" typeDocName="balanco-ano-anterior" />
                        <UploadFile extensionFile={downloadDocCurrentYearBalanceSheetExt}  onRemove={() => setDownloadDocCurrentYearBalanceSheet('')}  numberDoc={8} downloadDoc={downloadDocCurrentYearBalanceSheet} docName="Balanço ano vigente" description="(Obrigatório)" fileType="Arquivos PDF até 5 mb" typeDocName="balanco-ano-vigente" />
                        <UploadFile extensionFile={downloadDocDrePreviousYearExt}  onRemove={() => setDownloadDocDrePreviousYear('')}  numberDoc={9} downloadDoc={downloadDocDrePreviousYear} docName="DRE ano anterior" description="(Obrigatório)" fileType="Arquivos PDF até 5 mb" typeDocName="dre-ano-anterior" />
                        <UploadFile extensionFile={downloadDocDreCurrentYearExt}  onRemove={() => setDownloadDocDreCurrentYear('')}  numberDoc={10} downloadDoc={downloadDocDreCurrentYear} docName="DRE ano vigente" description="(Obrigatório)" fileType="Arquivos PDF até 5 mb" typeDocName="dre-ano-vigente" />
                    </>
                )}

                {companyType === 4 && <UploadFile extensionFile={downloadDocBankIndebtednessExt}  onRemove={() => setDownloadDocBankIndebtedness('')}  numberDoc={11} downloadDoc={downloadDocBankIndebtedness} docName="Relação de Endividamento Bancário" description="(Obrigatório)" fileType="Arquivos PDF até 5 mb" typeDocName="relacao-endividamento-bancario" />}

                {showProcuration && <UploadFile extensionFile={downloadDocProcurationExt}  onRemove={() => setDownloadDocProcuration('')}  numberDoc={13} downloadDoc={downloadDocProcuration} docName="Procuração" description="(Obrigatório)" fileType="Arquivos PDF até 5 mb" typeDocName="procuracao" />}

                <UploadFile extensionFile={downloadDocOthersExt}  onRemove={() => setDownloadDocOthers('')}  numberDoc={12} downloadDoc={downloadDocOthers} docName="Outros" description="" fileType="Arquivos PDF até 5 mb" typeDocName="outros" />
            </S.GridContainer>
            <div className="instructions">
            • Arraste e solte o arquivo ou clique para selecionar e carregar na(s) área(s) acima <br />
            • O arquivo pode ter até 5mb, aceitos no formato PDF, PNG e JPEG<br />
            • Documentos protegidos por senha, rasurados ou ilegíveis não serão aceitos
            </div>
        </S.UploadsContent>
    );
};

export default CompanyUploads;
