import Footer from "components/Footer";
import Header from "components/Header";
import React from "react";
import { useHistory } from "react-router-dom";
import { ConfirmationContent } from "./styles";
import Breadcrumb from "../../shared/breadcrumb";
import { Button } from "antd";
import CheckOrder from "../../../../../../assets/icons/order-check.svg";

const OrderConfirmation: React.FC<any> = ({}) => {
    const history = useHistory();

    const handleNavigate = (menu: any) => {
        history.push('/my-orders');

    };

    const menuItems = [
        {
            label: "Meus Pedidos",
            url: "/my-orders",
        },
        {
            label: "Novo Pedido",
            url: "/my-orders",
        },
        {
            label: "Configuração do Pedido",
            url: "/my-orders",
        },
        {
            label: "Checkout",
        },
        {
            label: "Pedido Enviado",
        },
    ];

    return (
        <>
            <Header />
            <Breadcrumb menuItems={menuItems} />
            <ConfirmationContent>
                <div className="container-xxl">
                    <div className="row">
                        <div className="col">
                        <h1>
                    Pedido Enviado
                </h1>
                <div className="status">
                            Seu pedido está em análise
                        </div>
                <div className="text">
                    <div className="image">
                        <img src={CheckOrder} alt="" />
                    </div>
                    <div className="content">
                        <p>
                            O pedido foi enviado com sucesso para nossa equipe.<br />
                            Acompanhe o andamento e siga as instruções que serão enviadas por e-mail para prosseguirmos com a assinatura!
                        </p>
                    </div>
                </div>
                <div className="action">
                <Button className="btn-continue" onClick={handleNavigate}>
                    Voltar para tela inicial de pedidos
                </Button>
                </div>
                        </div>
                    </div>
                </div>
            </ConfirmationContent>
            <Footer />
        </>
    );
};

export default OrderConfirmation;
