import React, { useState, useCallback, useEffect, useRef } from "react";
import Footer from "components/Footer";
import Header from "components/Header";
import OrderResume from "../../components/orderResume";
import CompanyForm from "../../components/PJ/companyForm";
import * as S from "./styles";
import { Button, Tabs } from "antd";
import CompanyFinder from "../../components/PJ/companyFinder";
import CompanyUploads from "../../components/PJ/companyUploads";
import PickupLocationPJ from "../../components/PJ/pickupLocationPJ";
import OrderResumePJ from "../../components/PJ/orderResume";
import { useToast } from "hooks/toast";
import api from "services/api";
import { useAuth } from "hooks/auth";
import { CheckCircleOutlined, DownloadOutlined } from "@ant-design/icons";
import CheckStep from "../../../../../assets/icons/check-step.svg";
import Breadcrumb from "../../components/shared/breadcrumb";
import { useHistory } from "react-router-dom";
import html2canvas from "html2canvas";
import ProposalPDF from "components/ProposalPDF";
import jsPDF from "jspdf";

const Checkout: React.FC<any> = () => {
    const { vendedorMaster } = useAuth();
    const { fullname, dealershipId, userId } = useAuth();
    const hiddenContainerRef = useRef<HTMLDivElement>(null);
    const [productsList, setProductsList] = useState<any[]>(JSON.parse(localStorage.getItem("@FleetSolutions:cart")!) || []);
    const [companyInfos, setCompanyInfos] = useState<any>(JSON.parse(localStorage.getItem("@FleetSolutions:companyInfos")!) || null);
    const [currentTab, setCurrentTab] = useState<string>(localStorage.getItem("currentTab") || "1");
    const [segment, setSegment] = useState<any>(JSON.parse(localStorage.getItem("@FleetSolutions:currentSelectedSegment")!) || null);
    const [isLoading, setIsLoading] = useState<any>(false);
    const companyFinderRef = useRef<any>(null);
    const companyFormRef = useRef<any>(null);
    const prevProductsListRef = useRef(productsList);
    const [obsValue, setObsValue] = useState("");
    const history = useHistory();
    const { addToast } = useToast();

    const [showPDFComponent, setShowPDFComponent] = useState(false);
    const [generatingPDF, setGeneratingPDF] = useState(false);

    useEffect(() => {
        if (prevProductsListRef.current !== productsList) {
            prevProductsListRef.current = productsList;
        }
    }, [productsList]);

    const handleContinue = () => {
        setIsLoading(true)
        if (currentTab === "1") {
            companyFinderRef.current.submitForm();
            setTimeout(() => {
                setIsLoading(false)
            }, 1000);
        }
        if (currentTab === "2") {
            companyFormRef.current.submitForm();
            setTimeout(() => {
                setIsLoading(false)
            }, 1000);
        }
        if (currentTab === "3") {
            handleDocuments();
        }
        if (currentTab === "4") {
            handlePickUpLocation();
        }
        if (currentTab === "5") {
            handleFinalize();
        }
    };

    const handleBack = () => {
        const currentTabAsNumber = Number(currentTab);
        const previousTab = currentTabAsNumber - 1;
        if (previousTab > 0) {
            localStorage.setItem("currentTab", previousTab.toString());
            setCurrentTab(previousTab.toString());
        }
    };

    const errorMessage = () => {
        return addToast({
            title: "Selecione o local de retirada",
            description: "É necessário selecionar o local de retirada para todos os veículos.",
            type: "error",
        });
    };

    const handlePickUpLocation = () => {
        const isValid = productsList.every((item) => {
            if (!item.pickUpLocationInfos) {
                errorMessage();
                return false;
            }
            if (item.pickUpLocationInfos.deliveryOption === null) {
                errorMessage();
                return false;
            }

            if (item.pickUpLocationInfos.deliveryOption === 5 && (!item.pickUpLocationInfos.city || item.pickUpLocationInfos.city === null || !item.pickUpLocationInfos.uf || item.pickUpLocationInfos.uf === null)) {
                errorMessage();
                return false;
            }

            if (item.pickUpLocationInfos.deliveryOption === 3 && (item.pickUpLocationInfos.address === null || Object.values(item.pickUpLocationInfos.address).some((value) => value === null))) {
                errorMessage();
                return false;
            }

            if (item.pickUpLocationInfos.canChosseLicensePlate && item.pickUpLocationInfos.willChosseLicensePlate && (!item.pickUpLocationInfos.licensePlate || item.pickUpLocationInfos.licensePlate === null || item.pickUpLocationInfos.licensePlate.length < 2)) {
                addToast({
                    title: "Final de placa",
                    description: "É necessário selecionar ao menos duas opções de final de placa.",
                    type: "error",
                });
                return false;
            }

            return true;
        });

        if (!isValid) {
            setIsLoading(false)

            return false;
        } else {
            setIsLoading(false)

            localStorage.setItem("@FleetSolutions:cart", JSON.stringify(productsList));
            localStorage.setItem("currentTab", "5");
            setCurrentTab("5");
        }
    };

    const handleFinalize = async () => {
        setIsLoading(true)
        const transformedProducts = productsList.map((item) => {
            const pickUpLocationInfos = item.pickUpLocationInfos || {};

            let { deliveryOption, city, uf, address, licensePlate, dealer } = pickUpLocationInfos;

            if (Array.isArray(city)) {
                city = city[0];
            }

            return {
                productId: item.productTemplate.productId,
                quantity: item.quantity,
                optional: item.productTemplate.optional,
                ...(deliveryOption && { deliveryOption }),
                ...(deliveryOption !== 1 && city && { cityForPickup: city }),
                ...(deliveryOption !== 1 && uf && { uf }),
                ...(address && { address }),
                ...(licensePlate && { finalPlate: licensePlate.join(" ") }),
                ...(dealer && { dealershipId: dealer }),
            };
        });
        console.log(transformedProducts);
        await api
            .post("/dealership-management-write", {
                products: transformedProducts,
                userId: companyInfos?.userId,
                dealershipId: Number(localStorage.getItem("@FleetSolutions:dealershipId")),
                dealershipGroupId: Number(localStorage.getItem("@FleetSolutions:dealershipGroupId")),
                note: obsValue,
                vendedorMaster: vendedorMaster,
            })
            .then((res: any) => {
                setIsLoading(false)

                history.push("/pedido-confirmado");

                localStorage.removeItem("@FleetSolutions:cart");
                localStorage.removeItem("@FleetSolutions:location");
                localStorage.removeItem("@FleetSolutions:userIdCompany");
                localStorage.removeItem("@FleetSolutions:note");
                localStorage.removeItem("@FleetSolutions:orderItems");
                localStorage.removeItem("currentTab");

                //   setDisabledOrder(false)
            })
            .catch(({ response }: any) => {
                setIsLoading(false)
                addToast({
                          title: 'Ocorreu um erro!',
                          type: 'error',
                          description: response.data
                        })
                return
                //   if (response.status !== 422) {
                //     addToast({
                //       title: 'Ocorreu um erro!',
                //       type: 'error',
                //       description: response.data
                //     })
                //     return
                //   }
                //   const outOfStockProductsData = response.data?.OutOfStockProducts
                //   const getProductsWithStock = (
                //     products: Array<IProductsOrder>,
                //     outOfStockProducts: Array<{
                //       ProductId: number
                //       QuantityStock: number
                //     }>
                //   ) => {
                //     return products.reduce(
                //       (withStock: Array<IProductsOrder>, product: IProductsOrder) => {
                //         const isOutOfStock = (outOfStock: {
                //           ProductId: number
                //           QuantityStock: number
                //         }) => {
                //           return product.productId === outOfStock.ProductId
                //         }
                //         const firstOutOfStok = outOfStockProducts.find(isOutOfStock)
                //         if (firstOutOfStok === undefined) return [...withStock, product]
                //         const currentItemAdded = withStock.filter(
                //           (item) => item.productId === product.productId
                //         )
                //         const hasQuantityInStock =
                //           currentItemAdded.length < firstOutOfStok.QuantityStock
                //         return hasQuantityInStock ? [...withStock, product] : withStock
                //       },
                //       []
                //     )
                //   }
                //   const locationWithStock = getProductsWithStock(
                //     location,
                //     outOfStockProductsData
                //   )
                //   setOutOfStockProducts(outOfStockProductsData)
                //   setLocation(locationWithStock)
                //   setStockModal(true)
            });
    };

    const handleOnSubmitSuccessCompanyFinder = () => {
        console.log("Formulário enviado com sucesso!");
        localStorage.setItem("currentTab", "2");
        setCurrentTab("2");
        setIsLoading(false)
    };

    const handleOnSubmitSuccessCompanyForm = () => {
        console.log("Formulário enviado com sucesso!");
        localStorage.setItem("currentTab", "3");
        setCurrentTab("3");
        setIsLoading(false)
    };

    const handleDocuments = async () => {
        const storedCompanyInfos = JSON.parse(localStorage.getItem("@FleetSolutions:companyInfos")!);
        setCompanyInfos(storedCompanyInfos);

        if (storedCompanyInfos) {
            try {
                const res = await api.get(`/document-company-upload/${storedCompanyInfos?.userId}`);
                const documents = res.data;

                // Verifique se os documentos obrigatórios estão presentes
                const requiredDocuments = [
                    { key: "ccmei", message: "Para concluir o pedido é necessário enviar o Contrato Social ou CCMEI." },
                    { key: "simplesStatementLastYear", message: "Para concluir o pedido é necessário enviar a Declaração do SIMPLES Último Ano.", condition: documents.companyType === 1 },
                    { key: "lastYearSocialIncomeTax", message: "Para concluir o pedido é necessário enviar o IRPF do Socio Último Ano.", condition: documents.companyType === 1 },
                    { key: "lastContractChange", message: "Para concluir o pedido é necessário enviar a Última Alteração Contratual.", condition: documents.companyType !== 1 },
                    { key: "irpjLastYear", message: "Para concluir o pedido é necessário enviar o IRPJ Último Ano.", condition: documents.companyType === 2 || documents.companyType === 3 },
                    { key: "lastYearRevenue", message: "Para concluir o pedido é necessário enviar a Relação de Faturamento Último Ano.", condition: documents.companyType === 2 || documents.companyType === 3 },
                    { key: "balanceSheetPreviousYear", message: "Para concluir o pedido é necessário enviar o Balanço ano anterior.", condition: [4, 0, null, undefined].includes(documents.companyType) },
                    { key: "currentYearBalanceSheet", message: "Para concluir o pedido é necessário enviar o Balanço ano vigente.", condition: [4, 0, null, undefined].includes(documents.companyType) },
                    { key: "drePreviousYear", message: "Para concluir o pedido é necessário enviar o DRE ano anterior.", condition: [4, 0, null, undefined].includes(documents.companyType) },
                    { key: "dreCurrentYear", message: "Para concluir o pedido é necessário enviar o DRE ano vigente.", condition: [4, 0, null, undefined].includes(documents.companyType) },
                    { key: "bankIndebtedness", message: "Para concluir o pedido é necessário enviar a Relação de Endividamento Bancário.", condition: documents.companyType === 4 },
                    { key: "procuration", message: "Para concluir o pedido é necessário enviar a procuração.", condition: true },
                    { key: "others", message: "Para concluir o pedido é necessário enviar outros documentos.", condition: false }
                ];
                

                for (const doc of requiredDocuments) {
                    if (documents[doc.key] === null && (doc.condition === undefined || doc.condition)) {
                        addToast({
                            title: "Documento pendente",
                            description: doc.message,
                            type: "error",
                        });
                        setIsLoading(false)
                        return;
                    }
                }

                localStorage.setItem("currentTab", "4");
                setCurrentTab("4");
                setIsLoading(false)

            } catch (err) {
                setIsLoading(false)
                console.error("Erro capturado em handleDocuments:", err);
                addToast({
                    title: "Erro no pedido",
                    description: "Para concluir o pedido é necessário enviar todos os documentos.",
                    type: "error",
                });
            }
        } else {
            setIsLoading(false)

            addToast({
                title: "Erro de dados",
                description: "Informações da empresa não encontradas. Atualize a página e tente novamente.",
                type: "error",
            });
        }
    };

    const handleObsChange = (value: string) => {
        setObsValue(value);
    };

    const renderTabHeader = (step: number, currentStep: number, label: string) => {
        let tabClass = "tab-item";

        if (step < currentStep) {
            tabClass += " tab-item-previous";
        } else if (step === currentStep) {
            tabClass += " tab-item-active";
        } else {
            tabClass += " tab-item-next";
        }

        return (
            <div className={tabClass}>
                <div className="tab-circle">{step < currentStep ? <img src={CheckStep} /> : step}</div>
                <span>{label}</span>
            </div>
        );
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [currentTab]);

    const currentStep = parseInt(currentTab, 10);

    const menuItems = [
        {
            label: "Meus Pedidos",
            url: "/my-orders",
        },
        {
            label: "Novo Pedido",
            url: `/ofertas/pj/${segment?.iD_Fleet}`,
        },
        {
            label: "Configuração do Pedido",
            url: "/configurar-pedido",
        },
        {
            label: "Checkout",
        },
    ];

    const proposalDate = () => {
        const currentDate = new Date();

        const day = currentDate.getDate().toString().padStart(2, "0");
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const year = currentDate.getFullYear().toString();

        const hours = currentDate.getHours().toString().padStart(2, "0");
        const minutes = currentDate.getMinutes().toString().padStart(2, "0");

        const formattedDateTime = `${day}/${month}/${year} - ${hours}:${minutes}`;

        return formattedDateTime;
    };

    const handleGeneratePdf = () => {
        setShowPDFComponent(true);
        setGeneratingPDF(true);
        setTimeout(() => {
          if (hiddenContainerRef.current) {
            html2canvas(hiddenContainerRef.current, {
                scale: 1,
                useCORS: true
            }).then((canvas) => {
                const contentWidth = canvas.width;
                const contentHeight = canvas.height;
    
                const pdfWidth = contentWidth; 
                const pdfHeight = contentHeight;
    
                const doc:any = new jsPDF({
                    orientation: 'portrait',
                    unit: 'pt',
                    format: [pdfWidth, pdfHeight],
                });
   
                doc.setPrecision(2);
                doc.setFont("Open Sans", "normal");
                doc.html(hiddenContainerRef.current as HTMLElement, {
                    x: 0,
                    y: 0,
                    html2canvas: {
                        scale: 1,
                        useCORS: true,
                    },
                    callback: function (doc:any) {
                        const totalPages = doc?.internal?.getNumberOfPages();
                        if (totalPages > 1) {
                            doc.deletePage(totalPages);
                        }
                        setTimeout(() => {
                          doc.save("Proposta_Comercial_Canal_Indireto.pdf");
                        }, 500);
                        setShowPDFComponent(false);
                        setGeneratingPDF(false);
  
                    },
                });
            });
        }
        }, 1000);
      };

    return (
        <>
            <Header />
            {showPDFComponent && (
                  <div ref={hiddenContainerRef} style={{ position: "fixed", zIndex: "-9999999999", overflow: "hidden", top: 0 }}>
                    <ProposalPDF
                        parameters={{
                            productsList:productsList,
                            seller: fullname,
                            segmentSummary: segment?.orderSummary,
                            proposalDate: proposalDate(),
                            segment: segment,
                            dealer:fullname
                        }}
                    />
                  </div>
            )}
            <Breadcrumb menuItems={menuItems} />
            <S.CheckoutContent>
                <div className="container-xxl">
                    <div className="row">
                        <div className="col">
                            <div className="checkout-head">
                                <h1 className="checkout-title">Checkout</h1>
                                {currentTab === "5" && (
                                    <Button loading={generatingPDF} onClick={() => handleGeneratePdf()} className="btn-print">
                                        <div className="print">
                                            <DownloadOutlined color="#236FBC" size={30} /> Exportar PDF da Proposta
                                        </div>
                                    </Button>
                                )}
                            </div>
                            <Tabs defaultActiveKey={currentTab} activeKey={currentTab}>
                                <Tabs.TabPane disabled={currentTab !== "1"} tab={renderTabHeader(1, currentStep, "Identificação")} key="1">
                                    <CompanyFinder ref={companyFinderRef} onSubmitSuccess={handleOnSubmitSuccessCompanyFinder} />
                                </Tabs.TabPane>
                                <Tabs.TabPane disabled={currentTab !== "2"} tab={renderTabHeader(2, currentStep, "Dados da Empresa")} key="2">
                                    <CompanyForm currentTab={currentTab} ref={companyFormRef} onSubmitSuccess={handleOnSubmitSuccessCompanyForm} />
                                </Tabs.TabPane>
                                <Tabs.TabPane disabled={currentTab !== "3"} tab={renderTabHeader(3, currentStep, "Documentação")} key="3">
                                    <CompanyUploads currentTab={currentTab} />
                                </Tabs.TabPane>
                                <Tabs.TabPane disabled={currentTab !== "4"} tab={renderTabHeader(4, currentStep, "Entrega do Veículo")} key="4">
                                    <PickupLocationPJ productsList={productsList} setProductsList={setProductsList} />
                                </Tabs.TabPane>
                                <Tabs.TabPane disabled={currentTab !== "5"} tab={renderTabHeader(5, currentStep, "Resumo do Pedido")} key="5">
                                    <OrderResumePJ currentTab={currentTab} onObsChange={handleObsChange} />
                                </Tabs.TabPane>
                            </Tabs>
                            {currentTab !== "5" && <OrderResume productsList={productsList} />}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="buttons">
                                <div className="wrapper-btn">
                                    {currentTab !== "1" && (
                                        <Button className="btn-back" onClick={handleBack}>
                                            Voltar
                                        </Button>
                                    )}
                                    <Button className="btn-continue" onClick={handleContinue} loading={isLoading}>
                                        {currentTab === "5" ? "Finalizar Pedido" : "Continuar"}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </S.CheckoutContent>
            <Footer />
        </>
    );
};

export default Checkout;
