// cnpjValidation.ts

const isValidCNPJ = (cnpj: string): boolean => {
    cnpj = cnpj.replace(/\D/g, ""); // Remove non-numeric characters

    if (cnpj.length !== 14) return false;

    // Reject known invalid CNPJs
    const invalidCNPJs = [
        "00000000000000",
        "11111111111111",
        "22222222222222",
        "33333333333333",
        "44444444444444",
        "55555555555555",
        "66666666666666",
        "77777777777777",
        "88888888888888",
        "99999999999999",
    ];
    if (invalidCNPJs.includes(cnpj)) return false;

    // Validate CNPJ digits using checksum
    let length = cnpj.length - 2;
    let numbers = cnpj.substring(0, length);
    const digits = cnpj.substring(length);
    let sum = 0;
    let pos = length - 7;

    for (let i = length; i >= 1; i--) {
        sum += parseInt(numbers[length - i], 10) * pos--; // Parse each character as a number
        if (pos < 2) pos = 9;
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(0), 10)) return false;

    length = length + 1;
    numbers = cnpj.substring(0, length);
    sum = 0;
    pos = length - 7;

    for (let i = length; i >= 1; i--) {
        sum += parseInt(numbers[length - i], 10) * pos--; // Parse each character as a number
        if (pos < 2) pos = 9;
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    return result === parseInt(digits.charAt(1), 10);
};

export { isValidCNPJ }; // Explicitly export the function


const isValidCPF = (cpf: string): boolean => {
    cpf = cpf.replace(/\D/g, ""); // Remove caracteres não numéricos

    if (cpf.length !== 11) return false;

    // Rejeita CPFs conhecidos como inválidos
    const invalidCPFs = [
        "00000000000",
        "11111111111",
        "22222222222",
        "33333333333",
        "44444444444",
        "55555555555",
        "66666666666",
        "77777777777",
        "88888888888",
        "99999999999",
    ];
    if (invalidCPFs.includes(cpf)) return false;

    // Validação do primeiro dígito verificador
    let sum = 0;
    for (let i = 0; i < 9; i++) {
        sum += parseInt(cpf.charAt(i), 10) * (10 - i);
    }
    let result = (sum * 10) % 11;
    if (result === 10 || result === 11) result = 0;
    if (result !== parseInt(cpf.charAt(9), 10)) return false;

    // Validação do segundo dígito verificador
    sum = 0;
    for (let i = 0; i < 10; i++) {
        sum += parseInt(cpf.charAt(i), 10) * (11 - i);
    }
    result = (sum * 10) % 11;
    if (result === 10 || result === 11) result = 0;
    return result === parseInt(cpf.charAt(10), 10);
};

export { isValidCPF }; // Exporta a função explicitamente
