import React, { useEffect, useState } from "react";
import { Upload } from "antd";
import { AiOutlineUpload } from "react-icons/ai";
import { UploadChangeParam } from "antd/lib/upload";

import Button from "../../../../../../components/Button";
import { useToast } from "../../../../../../hooks/toast";
import api from "../../../../../../services/api";
import { FileBox, WrapperDoc } from "./styles";

import iconUpload from "assets/icons/upload.svg";
import dockCheck from "assets/icons/doc-check.svg";

type UploadDocProps = {
    docName: string;
    description: string;
    fileType: string;
    typeDocName: string;
    downloadDoc?: string | null;
    extensionFile?: string | null;
    numberDoc: number;
    onRemove: () => void;
};

const UploadFile = ({ docName, description, fileType, typeDocName, downloadDoc, extensionFile, numberDoc, onRemove }: UploadDocProps) => {
    const { addToast } = useToast();
    const [companyInfos, setCompanyInfos] = useState<any>(JSON.parse(localStorage.getItem("@FleetSolutions:companyInfos")!) || null);

    const [showUpload, setShowUpload] = useState(true);
    const [typeDoc, setTypeDoc] = useState("");
    const [fileExtension, setFileExtension] = useState<string | null>(null);
    const [localDownloadDoc, setLocalDownloadDoc] = useState<string | null>(downloadDoc || null);

    useEffect(() => {
        setLocalDownloadDoc(downloadDoc || null);
        setFileExtension(extensionFile || null);
    }, [downloadDoc, extensionFile]);

    const uploadProps = {
        name: "file",
        multiple: false,
        accept: ".pdf",
        maxCount: 1,
        showUploadList: showUpload,
        beforeUpload: (file: File) => {
            if (file.type !== "application/pdf") {
                setShowUpload(false);
                addToast({
                    title: "Erro ao enviar arquivo",
                    type: "error",
                    description: "Envie arquivos do tipo PDF.",
                });
                return Upload.LIST_IGNORE;
            }

            setShowUpload(true);

            const uploadFile: any = {
                uid: Date.now().toString(),
                name: file.name,
                status: "uploading",
                originFileObj: file,
                size: file.size,
                type: file.type,
            };

            handleFileUpload({ file: uploadFile, fileList: [uploadFile] });
            return false;
        },
    };

    const handleFileUpload = async (info: UploadChangeParam<any>) => {
        const file = info.file.originFileObj as File;
        if (file.size && file.size > 5000000) {
            addToast({
                title: "Falha ao enviar documento",
                type: "error",
                description: "O tamanho do arquivo é maior que 5 MB",
            });
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        try {
            const response = await api.post(`/document-company-upload?userId=${companyInfos?.userId}&typeDocumentCompany=${numberDoc}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Accept: "text/plain",
                },
            });
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Data = reader.result?.toString().split(",")[1];
                setLocalDownloadDoc(base64Data || null);
            };
            reader.readAsDataURL(file);

            const fileExt = file.name.split(".").pop();
            setFileExtension(fileExt || null);

            addToast({
                title: "Upload bem-sucedido",
                type: "success",
                description: "Documento enviado com sucesso.",
            });
        } catch (err) {
            addToast({
                title: "Erro ao fazer upload",
                type: "error",
                description: (err as any).response?.data || "Erro inesperado ao fazer upload",
            });
        }
    };

    const handleFileOpen = () => {
        if (localDownloadDoc) {
            const link = document.createElement("a");
            link.href = `data:application/pdf;base64,${localDownloadDoc}`;
            link.download = `${typeDocName}.${fileExtension}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            addToast({
                title: "Erro",
                type: "error",
                description: "Nenhum arquivo disponível para download.",
            });
        }
    };

    return (
        <>
            {!localDownloadDoc && (
                <WrapperDoc onClick={() => setTypeDoc(typeDocName)} onDrop={() => setTypeDoc(typeDocName)}>
                    <Upload.Dragger className="upload" {...uploadProps}>
                        <img src={iconUpload} alt="Upload" />
                        <div className="doc-name">{docName}</div>
                        {localDownloadDoc ? "Remover" : ""}
                    </Upload.Dragger>
                </WrapperDoc>
            )}
            {localDownloadDoc && (
                <FileBox>
                    <img src={dockCheck} />
                    <div className="doc-name">{docName}</div>
                    <div className="doc-file" onClick={handleFileOpen}>
                        {typeDocName}.{fileExtension}
                    </div>
                    <Button className="btn-back" onClick={onRemove}>
                        Remover
                    </Button>
                </FileBox>
            )}
        </>
    );
};

export default UploadFile;
